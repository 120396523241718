import { Question } from "../types";


export type GTMEvents = "buttonClick" | "valueUpdated" | "productLoaded" | "callToAction";

export type DataLayerEventData = {
    event: GTMEvents;
    metadata: {
        product: "quickCheck" | "evFinder";
        page: Question | "welcome" | "results" | "-";
        value?: "next" | "back" | "skip" | "startOver" | "filters" | "sorting" | "moreInfo" | "electrificationPlanner" | "electrifyDriver" | "evFinder" | "leasingOffer" | "startOver" | "backToLastPage" | "backToFirstPage";
    }
}



function getSessionCookie() {
    const cookies = document.cookie.split('; ');
    const sessionCookie = cookies.find(row => row.startsWith('session_id='));
    return sessionCookie ? sessionCookie.split('=')[1] : null;
}

const sessionId = getSessionCookie();


export function pushToDataLayer(data: DataLayerEventData) {
    window.dataLayer.push({
        sessionId,
        ...data
    });
}

export function useGtmTracking() {


    const questionnaireEventTrackingWrapper = ({ action, page, callback }: { action: 'next' | 'back' | 'skip' | "startOver" | "backToLastPage" | "backToFirstPage", page: Question | "welcome" | "results", callback: () => void }) => {
        pushToDataLayer({
            event: 'buttonClick',
            metadata: {
                product: 'quickCheck',
                page,
                value: action
            }
        });
        callback();
    };

    const evFinderAdTrackingWrapper = ({ callback }: { callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product: 'quickCheck',
                page: 'results',
                value: 'evFinder'
            }
        })

        callback();
    }

    const electrificationPlannerAdTrackingWrapper = ({ callback }: { callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product: 'quickCheck',
                page: 'results',
                value: 'electrificationPlanner'
            }
        })

        callback();
    }

    const electrifyDriverAdTrackingWrapper = ({ product, callback }: { product: 'evFinder' | 'quickCheck', callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product,
                page: product === 'quickCheck' ? 'results' : "-",
                value: 'electrifyDriver'
            }
        })

        callback();
    }


    const evFinderEventTracking = ({ value, product, page, event }: { event: 'valueUpdated' | 'buttonClick', value: 'filters' | 'sorting' | 'moreInfo', product: 'evFinder' | 'quickCheck', page: 'results' | '-' }) => {
        pushToDataLayer({
            event,
            metadata: {
                product,
                value,
                page
            }
        })
    }


    const offerClickedEventTracking = ({ product, callback }: { product: 'evFinder' | 'quickCheck', callback: () => void }) => {
        pushToDataLayer({
            event: 'callToAction',
            metadata: {
                product,
                page: product === 'quickCheck' ? 'results' : '-',
                value: 'leasingOffer'
            }
        })
        callback();
    };


    return {
        questionnaireEventTrackingWrapper,
        evFinderAdTrackingWrapper,
        electrificationPlannerAdTrackingWrapper,
        electrifyDriverAdTrackingWrapper,
        evFinderEventTracking,
        offerClickedEventTracking
    }


}