import { ArrowLeft } from "../../../../components/ArrowLeft";
import { useRef, useState } from "react";
import { useArrayOutsideClickDetector } from "../../../../electrify_frontend_common/hooks/useOutsideClickAlerter";

export function BackButton({
    goBackToFirstPage,
    goBackToLastPage,
    startOver
}: {
    goBackToFirstPage: () => void,
    goBackToLastPage: () => void,
    startOver: () => void
}) {

    const [hovered, setHovered] = useState(false);
    const [backButtonClicked, setBackButtonClicked] = useState(false);

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    useArrayOutsideClickDetector([dropdownRef,buttonRef], () => {
        setBackButtonClicked(false);
    });

    return (
        <div className="flex w-full justify-start md:mb-4 relative">
            <div
                ref={buttonRef}
                className="flex items-center cursor-pointer pb-8 md:pb-0 w-fit text-white hover:text-Blueberry-dark-default"
                onClick={() => setBackButtonClicked(!backButtonClicked)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
                <ArrowLeft color={hovered ? "#830751" : "white"} />
                <span className="text-sm  ml-2 underline ">Return to questionnaire</span>
            </div>
            <div ref={dropdownRef} className={`absolute bg-Black-background border border-gray-400 rounded top-8 left-1 text-white text-sm ${backButtonClicked ? ' h-[112px]' : 'h-0 border-0'} transition-all duration-300 overflow-hidden`}>
                <div className="p-2 px-7 cursor-pointer text-center border-b border-gray-400 hover:bg-Blueberry-dark-default" onClick={goBackToFirstPage}>Back to first page</div>
                <div className="p-2 px-7 cursor-pointer text-center border-b border-gray-400 hover:bg-Blueberry-dark-default" onClick={goBackToLastPage}>Back to last page</div>
                <div className="p-2 px-7 cursor-pointer text-center hover:bg-Blueberry-dark-default" onClick={startOver}>Start again</div>
            </div>
        </div>
    )
}