import { useMutation } from "react-query";
import { QuestionnaireResults } from "../../../types";
import { ElectrifyDriveBanner } from "./components/ElectrifyDriveBanner";
import { EvRecommendations } from "./components/EvRecommendations";
import { sendQuestionnaireData } from "../../../services";
import { useEffect, useState } from "react";
import { Spinner, SpinnerSize } from "../../../components/Spinner";
import { useTranslation } from "react-i18next";
import { ElectrificationAssessment } from "./components/ElectrificationAssessment";
import { RecommendedActions } from "./components/RecommendedActions";
import { BackButton } from "./components/BackButton";
import ModalWrapper from "../../../electrify_frontend_common/components/ModalWrapper";

export function Results({
    goBackToFirstPage,
    goBackToLastPage,
    startOver
}: {
    goBackToFirstPage: () => void,
    goBackToLastPage: () => void,
    startOver: () => void
}) {
    const { t } = useTranslation("results");

    const storedLocationData = localStorage.getItem("userLatestHomeLocation");

    const [goBackModalOpen, setGoBackModalOpen] = useState(false);

    const userLatestHomeLocation: {
        address: string;
        coordinates: { lat: number; lon: number };
        countryCode: string;
    } = storedLocationData && JSON.parse(storedLocationData);

    const userHomeCountryCode = userLatestHomeLocation?.countryCode;
    const userCountryCode: string = "DE"; // Should be taken from either the IP of user or the country set for the device of user in the future

    const [results, setResults] = useState<QuestionnaireResults | null>(null);

    const mutation = useMutation(sendQuestionnaireData, {
        onSuccess: (data) => {
            setResults(data);
        },
        onError: (error) => {
            console.log("Error updating data: ", error);
        },
    });

    useEffect(() => {
        const answers = JSON.parse(localStorage.getItem("answers") || "{}");
        mutation.mutate({
            ...answers,
            userCountryCode: userHomeCountryCode || userCountryCode || "DE",
        });
    }, []);

    if (!results)
        return (
            <div className="flex w-full h-full items-center justify-center">
                <Spinner size={SpinnerSize.LARGE} />
                <div className="text-xl text-white ml-2">
                    Preparing your results...
                </div>
            </div>
        );

    return (
        <>
            <ModalWrapper
                isOpen={goBackModalOpen}
                close={() => setGoBackModalOpen(false)}
            >
                <div className=" bg-Black-background p-3 text-white">
                    <div className="p-2" onClick={goBackToFirstPage}>Back to first page</div>
                    <div className="p-2" onClick={goBackToLastPage}>Back to last page</div>
                    <div className="p-2" onClick={startOver}>Start again</div>
                </div>
            </ModalWrapper>
            <div className="text-white p-6 sm:px-12 md:px-12 flex flex-col w-full h-full items-center justify-center ">
                <div className="max-w-screen-lg">
                    <BackButton
                        goBackToFirstPage={goBackToFirstPage}
                        goBackToLastPage={goBackToLastPage}
                        startOver={startOver}
                    />
                    <div className="flex flex-col items-center ">
                        <h4 className="md:text-xl mb-3">{t("label")}</h4>
                        <h1 className="text-[30px] text-center md:text-[40px]">
                            {t("title")}
                        </h1>
                        <div className="md:grid md:grid-cols-2 md:gap-4 w-full mt-10 ">
                            <div className="flex flex-col w-full">
                                <ElectrificationAssessment
                                    suitabilityCategory={
                                        results.suitabilityCategory
                                    }
                                />
                            </div>
                            <div className="flex flex-col w-full mt-4 md:mt-0">
                                <RecommendedActions
                                    recommendedActions={results.recommendedActions}
                                />
                            </div>
                        </div>
                        <div className="mt-8">
                            <ElectrifyDriveBanner product='quickCheck' />
                        </div>

                        <div className="mt-8 flex w-full h-full">
                            <EvRecommendations results={results} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
