import { useEffect, useState } from "react";
import { Questionnaire } from "./pages/Questionnaire";
import { Welcome } from "./pages/Welcome";
import { Results } from "./pages/Results";
import { Question } from "../types";
import { useGtmTracking } from "../hooks/useGtmTracking";

export function EMobilityQuickCheck() {
    const getCurrentStep = () => {
        if (localStorage.getItem("questionnaireCompleted") === "true") return 2;
        return localStorage.getItem("currentQuestion") ? 1 : 0;
    };



    const { questionnaireEventTrackingWrapper } = useGtmTracking();

    const [step, setStep] = useState(getCurrentStep());

    const steps = [
        {
            component: <Welcome next={() => questionnaireEventTrackingWrapper({
                action: 'next',
                page: "welcome",
                callback: () => setStep(1)
            })} />
        },
        { component: <Questionnaire showResults={() => setStep(2)} /> },
        {
            component: <Results
                goBackToFirstPage={() => {

                    questionnaireEventTrackingWrapper({
                        action: "backToFirstPage",
                        page: 'results',
                        callback: () => {
                            localStorage.setItem("questionnaireCompleted", "false");
                            localStorage.setItem("currentQuestion", Question.HOME_CHARGING); // For Campaign: Switched from E_MOBILITY
                            setStep(1);
                        }
                    })

                }}

                goBackToLastPage={() => {

                    questionnaireEventTrackingWrapper({
                        action: "backToLastPage",
                        page: 'results',
                        callback: () => {
                            localStorage.setItem("questionnaireCompleted", "false");
                            localStorage.setItem("currentQuestion", Question.NICE_TO_HAVE_FEATURES); // For Campaign: Switched from E_MOBILITY
                            setStep(1);
                        }
                    })

                }}

                startOver={() => {
                    questionnaireEventTrackingWrapper({
                        action: "startOver",
                        page: 'results',
                        callback: () => {
                            localStorage.setItem("questionnaireCompleted", "false");
                            localStorage.setItem("currentQuestion", Question.HOME_CHARGING); // For Campaign: Switched from E_MOBILITY
                            localStorage.removeItem("answers");
                            localStorage.removeItem("userLatestHomeLocation");
                            setStep(1);
                        }
                    })
                }}
            />
        },
    ];

    return <div className="flex flex-col flex-1 w-full justify-center items-center">{steps[step].component}</div>;
}
